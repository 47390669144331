<template>
  <a-modal :class="$style.container" v-model="isShow" :title="(this.form.id ? '编辑' : '新增')+'虚拟分类'" @ok="determine" @cancel="cancel">
    <a-form-model ref="form" :rules="goyRules" :class="$style.form__ct" class="al-form"
                  :label-col="{ style: 'width: 130px' }" :wrapper-col="{ style: 'width: 300px' }" layout="inline"
                  :model="form">
      <a-row type="flex" justify="space-between">
        <a-form-model-item label="上级分类">
          <a-tree-select
            v-model="form.idPerent"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="treeData"
            placeholder="请选择父级栏目"
            treeNodeFilterProp="label"
            :replaceFields="{children:'children', title:'name', key:'id', value: 'id' }"
            tree-default-expand-all
          >
            <span v-if="key === '0-0-1'" slot="name" slot-scope="{ key, value }" style="color: #08c">
              Child Node1 {{ value }}
            </span>
          </a-tree-select>
        </a-form-model-item>
      </a-row>
      <a-row type="flex" justify="space-between">
        <a-form-model-item label="分类名称:" prop="name">
          <a-input v-model="form.name" type="string" placeholder="请输入"/>
        </a-form-model-item>
      </a-row>
      <a-row type="flex" justify="space-between">
        <a-form-model-item label="排序:" prop="sort">
          <a-input v-model="form.sort" type="number" placeholder="请输入"/>
        </a-form-model-item>
      </a-row>
      <a-row type="flex" justify="space-between">
        <a-form-model-item label="图标（1张）" prop="iconUrl" style="margin-bottom:0">
        <ImageManage v-if="!disabledStatus" v-model="form.iconUrl" :square="false" :size="1024*1024*2" :showWidth="true">
            <div slot="extra">推荐比率1:1，支持jpg、png格式，大小不超过50k</div>
        </ImageManage>
          <!-- <a-input v-model="form.iconUrl" placeholder="请输入" style="display:none"/>
          <div style="display:flex">
            <a-upload v-if="!disabledStatus" name="file" list-type="picture-card"
                      class="avatar-uploader avatar-uploader1"
                      :show-upload-list="false" :action="$consts.uploadFileUrl"
                      :headers="$store.state.header" :before-upload="(e)=>{beforeUpload(e)}" @change="(e)=>{handleChange(e)}">
              <a-avatar shape="square" style="width:80px;height:80px" v-if="form.iconUrl" :src="form.iconUrl"/>
              <div v-else>
                <a-icon :type="loading1 ? 'loading' : 'plus'"/>
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
            <a-avatar shape="square" v-else style="width:102px;height:102px" :src="form.iconUrl"/>
          </div>
          <div slot="extra">推荐比率1:1，支持jpg、png格式，大小不超过50k</div> -->
        </a-form-model-item>
      </a-row>
      <br/>
    </a-form-model>
  </a-modal>
</template>

<script>
import ImageManage from '@/components/imageManage/index.vue'
export default {
  components: {
    ImageManage
  },
  data () {
    return {
      fatherGoy: {
        id: '0',
        value: '0',
        name: '无',
        children: []
      },
      overSize: true,
      uploadStatus: true,
      zanshiFile: '',
      btCount: 0,
      treeData: [],
      loading1: false,
      disabledStatus: false,
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      form: {},
      goyRules: {
        name: [{
          required: true,
          message: '名称不能为空',
          trigger: 'change'
        }],
        sort: [{
          required: true,
          message: '排序不能为空',
          trigger: 'change'
        }]
      }
    }
  },
  created () {
    this.queryTreeData()
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    setTreeFatherDisabled (alist, lever) {
      lever ? lever++ : lever = 1
      alist.map(item => {
        if (lever === 3) {
          item.disabled = true
        } else {
          item.disabled = false
          if (item.children) {
            item.children = this.setTreeFatherDisabled(item.children, lever)
          }
        }
      })
      return alist
    },
    async queryTreeData () {
      const res = await this.$store.dispatch('http', {
        api: 'goyVirtualList'
      })
      const treeSetDisabled = this.setTreeFatherDisabled(res)
      this.treeData = [this.fatherGoy, ...treeSetDisabled]
    },
    async goyGoodsInfo (id) {
      const res = await this.$store.dispatch('http', {
        api: 'goyGoodsInfo',
        query: { id }
      })
      console.log(res, 'res 分类详情')
      return res
    },
    resetForm () {
      this.form = {
        id: '',
        idPerent: '0',
        name: '',
        sort: undefined,
        iconUrl: ''
      }
    },
    // 设置表单
    async setForm (form = {}) {
      this.btCount = 0
      this.queryTreeData()
      this.resetForm()
      if (form.id) {
        this.form = {
          idPerent: form.idPerent,
          id: form.id,
          name: form.name,
          sort: form.sort,
          iconUrl: form.iconUrl
        }
      } else {
        this.form.idPerent = form.idPerent
      }
    },
    cancel () {
      this.$refs.form.resetFields()
      this.form = {
        name: '',
        sort: '',
        iconUrl: ''
      }
    },
    beforeUpload (file) {
      this.zanshiFile = file
      this.overSize = false
      let isOverSize
      isOverSize = file.size / 1024 < 50
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      setTimeout(() => {
        if (!isJpgOrPng) {
          this.$store.dispatch('showToast', { type: 'warning', message: '图片格式不正确' })
          return false
        }
        if (this.uploadStatus) {
          this.$store.dispatch('showToast', { type: 'error', message: '请上传图片宽高比率为1:1的像素图片' })
          return false
        }
        if (!isOverSize) {
          this.$store.dispatch('showToast', { type: 'error', message: '图片大小不能超过50KB' })
          this.overSize = true
        }
      }, 200)
      return isJpgOrPng && isOverSize
    },
    async handleChange (info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        this.getIsBiLi(this.zanshiFile)
        setTimeout(() => {
          if (!this.overSize && !this.uploadStatus) {
            this.form.iconUrl = info.file.response.result
          }
        }, 300)
        this.loading = false
      }
    },
    getIsBiLi (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const image = new Image()
        image.src = reader.result
        image.onload = () => {
          const w = image.width
          const h = image.height
          if (w / h !== 1) {
            this.uploadStatus = true
          } else {
            this.uploadStatus = false
          }
        }
      }
    },
    // 提交保存
    async determine (e) {
      if (!this.btCount) {
        this.goComfirm()
        this.btCount++
      }
      setTimeout(() => {
        this.btCount = 0
      }, 1000)
    },
    async goComfirm (e) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const params = this.$utils.clone(this.form)
          let api = 'goyVirtualAdd'
          if (params.id) {
            api = 'goyVirtualUpdate'
          }
          await this.$store.dispatch('http', {
            api,
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: (params.id ? '编辑' : '新增') + '虚拟分类成功~' })
          this.isShow = false
          this.$emit('save')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
