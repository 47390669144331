<template>
  <div id="container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
      <a-button class="add-bt" type="primary"  @click="$refs.goyVirtualDialog.setForm();$refs.goyVirtualDialog.isShow = true">新增</a-button>
    </Breadcrumb>
    <!-- 查询 -->
    <a-form-model
      ref="formRef"
      layout="inline"
      :model="form"
    >
      <a-form-model-item>
        <a-input v-model="form.name" placeholder="名称" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="search" type="primary" html-type="submit">查询</a-button>
        <span style="margin-left:20px;"> </span>
        <a-button  @click="resetForm('form')">重置</a-button>
      </a-form-model-item>
    </a-form-model>
    <br />
    <!-- 表格 -->
    <a-table
      bordered
      :expanded-row-keys.sync="expandedRowKeys"
      :columns="columns"
      :data-source="list"
      :pagination="false"
      :rowKey="(record) => record.id"
    >
      <span slot="iconUrl" slot-scope="text">
        <a-avatar shape="square" :src="text" />
      </span>
      <div slot="action" slot-scope="record">
        <span>
          <a v-if="record.level !=3"  @click="$refs.goyVirtualDialog.setForm({idPerent:record.id});$refs.goyVirtualDialog.isShow = true">新增</a>
          <a-divider v-if="record.level !=3" type="vertical" />
          <a  @click="$refs.goyVirtualDialog.setForm(record);$refs.goyVirtualDialog.isShow = true">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除此条信息吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="confirm(record.id)"
            @cancel="cancel"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </span>
      </div>
    </a-table>
    <add-goy-virtual ref="goyVirtualDialog" @save="reloadList"></add-goy-virtual>
  </div>
</template>

<script>
import addGoyVirtual from './addOrEdit.vue'
import Breadcrumb from '@/components/breadcrumb.vue'
export default {
  components: { addGoyVirtual, Breadcrumb },
  data () {
    return {
      expandedRowKeys: [],
      breadList: [
        {
          name: '商品管理',
          url: ''
        },
        {
          name: '虚拟分类',
          url: '/virtual/list'
        }
      ],
      Y: '', // 暂时不配置树形结构
      visible: false,
      doctorImag: '',
      form: {
        name: '',
        iconUrl: ''
      },
      columns: [
        {
          title: '名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '排序',
          dataIndex: 'sort',
          key: 'sort',
          width: 100,
          ellipsis: true
        },
        {
          title: '图标',
          dataIndex: 'iconUrl',
          key: 'iconUrl',
          scopedSlots: {
            customRender: 'iconUrl'
          }
        },
        {
          title: '更新时间',
          dataIndex: 'gmtModified',
          key: 'gmtModified',
          width: 180,
          ellipsis: true
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      total: 0,
      list: [],
      listData: [],
      selectedRowKeys: [],
      isQueryLoading: false
    }
  },
  created () {
    this.reloadList()
  },
  mounted () {
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
    async search () {
      this.expandedRowKeys = []
      await setTimeout(() => {
      }, 100)
      this.filterRowkeyIds(this.form, this.listData)
    },
    getTreeLeverLister () {
      this.listData.forEach((element) => {
        element.idList = []
        if (element.children && element.children.length > 0) {
          element.children.forEach((element2) => {
            element2.idList = [element.id]
            if (element2.children && element2.children.length > 0) {
              element2.children.forEach(element3 => {
                element3.idList = [element2.id, element.id]
              })
            }
          })
        }
      })
    },
    filterRowkeyIds (form, list) {
      let that = this
      const { name } = { ...form }
      if (!name) {
        this.expandedRowKeys = []
        return
      }
      list.forEach(ele => {
        if (name) {
          if (ele.name.indexOf(name) >= 0) {
            that.expandedRowKeys = [...that.expandedRowKeys, ...ele.idList]
          }
          if (ele.children && ele.children.length > 0) {
            that.filterRowkeyIds(form, ele.children)
          }
        }
      })
    },
    // 弹出窗口
    showModal ({ editorAdd, id, parentId }) {
      this.visible = true
      setTimeout(() => {
        this.$refs.refModal.init({ editorAdd, id, parentId })
      }, 200)
    },
    cancel (values) {
      console.log(values)
    },
    // 是否确认弹窗口  方法
    confirm (id) {
      this.delVirtual(id)
    },
    async delVirtual (id) {
      const res = await this.$store.dispatch('http', {
        api: 'goyVirtualDelete',
        query: { id: id }
      })
      if (res) {
        this.$store.dispatch('showToast', { type: 'success', message: '删除成功' })
        this.queryData()
      }
    },
    moment,
    // 重置表单
    resetForm () {
      this.form = {
        name: ''
      }
      this.list = []
      setTimeout(() => {
        this.queryList()
        this.filterRowkeyIds()
      }, 100)
    },
    queryData () {
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
        this.selectedRowKeys = []
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => { }) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => { }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const res = await this.$store.dispatch('http', {
        api: 'goyVirtualList',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      if (res) {
        this.pagination.total = res.length
        this.listData = res
        this.list = this.$utils.clone(res)
        this.getTreeLeverLister()
      }
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log(val)
      if (this.pagination.current === val.current) {
        this.pagination.current = 1
      } else {
        this.pagination.current = val.current
      }
      this.pagination.pageSize = val.pageSize
      this.loadList()
    },
    // 选中事件
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    zoom (scope) {
      this.visible = true
      this.doctorImag = scope
    }
  }
}
</script>

<style lang="scss" module>
</style>
